import { IAlert } from '@/shared/model/alert.model';
import { PromptDialogContent } from '@/shared/model/prompt-dialog-content.model';
import { ActionTree } from 'vuex';
import { AppStore } from './types';
import { RootState } from '@/shared/config/store/types';
import { ICustomer } from '@/shared/model/customer.model';
import { IModelSelection } from '@/shared/model/model-selection.model';
import { ICapaSelection } from '@/shared/model/capa-selection.model';

export const actions: ActionTree<AppStore, RootState> = {
  setSnackbar({ commit }, newSnackbar: boolean): void {
    commit('setSnackbar', newSnackbar);
  },
  setSearchTerm({ commit }, newSearchTerm: string): void {
    commit('setSearchTerm', newSearchTerm);
  },
  setSnackbarContent({ commit }, newSnackbarContent: IAlert[]): void {
    commit('setSnackbarContent', newSnackbarContent);
  },
  setPromptDialog({ commit }, newPromptDialog: boolean): void {
    commit('setPromptDialog', newPromptDialog);
  },
  setPromptDialogContent({ commit }, newPromptDialogContent: PromptDialogContent): void {
    commit('setPromptDialogContent', newPromptDialogContent);
  },
  setCustomer({ commit }, newCustomer: ICustomer): void {
    commit('setCustomer', newCustomer);
  },
  setIsRound({ commit }, newIsRound: boolean): void {
    commit('setIsRound', newIsRound);
  },
  setSavingCustomer({ commit }, newSavingCustomer: boolean): void {
    commit('setSavingCustomer', newSavingCustomer);
  },
  setDiagramVisibility({ commit }, newDiagramVisibility: IModelSelection): void {
    commit('setDiagramVisibility', newDiagramVisibility);
  },
  setCapaVisibility({ commit }, newCapaVisibility: ICapaSelection): void {
    commit('setCapaVisibility', newCapaVisibility);
  },
  setShowedAlertForThisCustomer({ commit }, newShowedAlertForThisCustomer: boolean): void {
    commit('setShowedAlertForThisCustomer', newShowedAlertForThisCustomer);
  },
  promptDialog({ commit }): Promise<boolean> {
    commit('setPromptDialog', true);
    return new Promise((resolve, reject) => {
      commit('setResolve', resolve);
      commit('setReject', reject);
    });
  },
  resolve({ state, commit }) {
    state.resolve(true);
    commit('setPromptDialog', false);
  },
  reject({ state, commit }) {
    state.reject(false);
    commit('setPromptDialog', false);
  },
};
