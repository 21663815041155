import AccountService from '@/account/account.service';
import { Component, Inject, Prop, Vue, Watch } from 'vue-property-decorator';
import { Authority } from '@/shared/security/authority';

@Component
export default class AppNavDrawer extends Vue {
  @Inject('accountService') private accountService: () => AccountService;

  $refs;
  @Prop() toggleDrawer!: boolean;
  private navDrawer = false;
  private systemParameterGroup = false;
  private systemSetting = false;
  private marketerParameterGroup = false;
  private marketerGroup = false;
  public drawer = false;
  public mini = true;

  @Watch('mini')
  closeAllGroups() {
    this.systemParameterGroup = false;
    this.marketerParameterGroup = false;
    this.systemSetting = false;
  }

  private get dataGroupCardColor() {
    return this.systemParameterGroup ? '' : 'transparent';
  }

  private get marketerGroupCardColor() {
    return this.marketerGroup ? '' : 'transparent';
  }
  public get authenticated(): boolean {
    return this.$store.getters.authenticated;
  }

  @Watch('toggleDrawer')
  public ontoggleDrawerChanged(val: boolean): void {
    this.drawer = !this.drawer;
  }

  public get username(): string {
    const account = this.$store.getters.account;
    return (account?.firstName ?? '') + ' ' + (account?.lastName ?? '');
  }

  private get isAdmin() {
    return (
      this.$store.getters.account != null &&
      this.$store.getters.account.authorities != null &&
      this.$store.getters.account.authorities.includes(Authority.ADMIN)
    );
  }

  private get isGroupAdmin() {
    return (
      this.$store.getters.account != null &&
      this.$store.getters.account.authorities != null &&
      this.$store.getters.account.authorities.includes(Authority.GROUP)
    );
  }

  private drawerChanged() {
    this.navDrawer = !this.$refs.drawer.isMiniVariant;
  }

  public logout(): void {
    this.drawer = false;
    this.mini = true;
    localStorage.removeItem('jhi-authenticationToken');
    sessionStorage.removeItem('jhi-authenticationToken');
    this.$store.commit('logout');
    this.$router.push('/');
  }
}
