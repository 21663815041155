import { IAlert } from '@/shared/model/alert.model';
import { PromptDialogContent } from '@/shared/model/prompt-dialog-content.model';
import { MutationTree } from 'vuex';
import { AppStore } from './types';

export const mutations: MutationTree<AppStore> = {
  setSnackbar(state, newSnackbar: boolean): void {
    state.snackbar = newSnackbar;
  },
  setSearchTerm(state, newSearchTerm: string): void {
    state.searchTerm = newSearchTerm;
  },
  setSnackbarContent(state, newSnackbarContent: IAlert[]): void {
    state.snackbarContent = newSnackbarContent;
  },
  setPromptDialog(state, newPromptDialog: boolean): void {
    state.promptDialog = newPromptDialog;
  },
  setPromptDialogContent(state, newPromptDialogContent: PromptDialogContent): void {
    state.promptDialogContent = newPromptDialogContent;
  },
  setResolve(state, newResolve): void {
    state.resolve = newResolve;
  },
  setReject(state, newReject): void {
    state.reject = newReject;
  },
  setCustomer(state, newCustomer): void {
    state.customer = newCustomer;
  },
  setIsRound(state, newIsRound): void {
    state.isRound = newIsRound;
  },
  setSavingCustomer(state, newSavingCustomer): void {
    state.savingCustomer = newSavingCustomer;
  },
  setDiagramVisibility(state, newDiagramVisibility): void {
    state.diagramVisibility = newDiagramVisibility;
  },
  setCapaVisibility(state, newCapaVisibility): void {
    state.capaVisibility = newCapaVisibility;
  },
  setShowedAlertForThisCustomer(state, newShowedAlertForThisCustomer): void {
    state.showedAlertForThisCustomer = newShowedAlertForThisCustomer;
  },
};
